import { SingleOptionType } from '@core/app/dropdown';
import { buildOption } from '@core/helpers/dropdown';
import { Platform } from '@prisma/client';

/**
 * Platforms that are supported retrieving the data using Supermetrics.
 */
const platformsCampaignsRetrieve = [
	Platform.FACEBOOK,
	Platform.GOOGLE_ADS_DISPLAY,
	Platform.GOOGLE_ADS_SEARCH,
	Platform.INSTAGRAM,
	Platform.MY_TARGET,
	Platform.QUORA,
	Platform.TWITTER,
	Platform.YANDEX_DISPLAY,
	Platform.YANDEX_SEARCH,
	Platform.YOUTUBE,
];

/**
 * Dropdown options used for Admin / Data Collection / Retrieve (Campaigns)
 */
export const retrieveCampaignsOptions: SingleOptionType[] = platformsCampaignsRetrieve.map(entry => buildOption(entry));
