import { LanguageService } from '@prisma/client';

export const languageServices = [
	LanguageService.AFAAN_OROMOO,
	LanguageService.AFRIQUE,
	LanguageService.AMHARIC,
	LanguageService.ARABIC,
	LanguageService.AZERI,
	LanguageService.BENGALI,
	LanguageService.BRASIL,
	LanguageService.BURMESE,
	LanguageService.CHINESE,
	LanguageService.DARI,
	LanguageService.GREAT_LAKES,
	LanguageService.GUJARATI,
	LanguageService.HAUSA,
	LanguageService.HINDI,
	LanguageService.IGBO,
	LanguageService.INDONESIAN,
	LanguageService.KOREAN,
	LanguageService.KYRGYZ,
	LanguageService.MARATHI,
	LanguageService.MUNDO,
	LanguageService.NEPALI,
	LanguageService.PAN_LANGUAGE,
	LanguageService.PASHTO,
	LanguageService.PERSIAN,
	LanguageService.PIDGIN,
	LanguageService.PUNJABI,
	LanguageService.RUSSIAN,
	LanguageService.SERBIAN,
	LanguageService.SINHALA,
	LanguageService.SOMALI,
	LanguageService.SWAHILI,
	LanguageService.TACTICAL,
	LanguageService.TAMIL,
	LanguageService.TELUGU,
	LanguageService.THAI,
	LanguageService.TIGRINYA,
	LanguageService.TURKISH,
	LanguageService.UKRAINIAN,
	LanguageService.URDU,
	LanguageService.UZBEK,
	LanguageService.VIETNAMESE,
	LanguageService.WORLD_SERVICE,
	LanguageService.YORUBA,
];
