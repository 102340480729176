import { config } from '@core/app/config';
import { states, StatesType } from '@8ms/helpers/dist/api';
import { post } from '@8ms/helpers/dist/axios';
import { UserRole } from '@prisma/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { destroyCookie } from 'nookies';

export const defaultAuthUser: AuthUser = {
	email:     '',
	firstName: '',
	id:        0,
	isActive:  false,
	lastName:  '',
	role:      undefined,
}

export type AuthUser = {
	email: string,
	firstName: string,
	id: number,
	isActive: boolean,
	lastName: string,
	role: UserRole,
}

export interface AuthState
{
	ajaxStatus: StatesType,
	darkMode: boolean,
	status: typeof states.ERROR | typeof states.SUCCESS,
	user: AuthUser,
}

const initialState: AuthState = {
	ajaxStatus: states.IDLE,
	darkMode:   true,
	status:     states.ERROR,
	user:       {
		...defaultAuthUser,
	},
};

/**
 * Get the user's logged in status onload
 */
export const checkSession = createAsyncThunk('auth/checkSession', async () =>
{
	const payload = {
		user:   defaultAuthUser,
		status: states.ERROR,
	};
	
	await post({
		onError:   response => {},
		onSuccess: response =>
		           {
			           if (!isEmpty(response))
			           {
				           payload.user   = response;
				           payload.status = isEmpty(response) ? states.ERROR : states.SUCCESS;
			           }
		           },
		url:       '/api/auth/checkCookie',
	});
	
	return payload;
});

/**
 * Log out and set the active session token to inactive
 */
export const logOut = createAsyncThunk('auth/logOut', async () =>
{
	await post({
		onError:   response => {},
		onSuccess: response => {},
		url:       '/api/auth/logOut',
	});
});

const authSlice = createSlice(
	{
		name:          'auth',
		initialState,
		reducers:      {
			logIn(state, action)
			{
				state.user   = {
					...action.payload.user,
				};
				state.status = states.SUCCESS;
			},
			updateDarkMode(state, action)
			{
				state.darkMode = action.payload.darkMode;
			},
		},
		extraReducers: builder =>
		               {
			               builder.addCase(checkSession.pending, (state) =>
			               {
				               state.ajaxStatus = states.PENDING;
			               }),
				               builder.addCase(checkSession.fulfilled, (state, action) =>
				               {
					               state.ajaxStatus = states.SUCCESS;
					               state.user       = {
						               ...action.payload.user,
					               };
					
					               // @ts-ignore
					               state.status = action.payload.status;
				               }),
				               builder.addCase(checkSession.rejected, (state, action) =>
				               {
					               state.ajaxStatus = states.ERROR;
					               state.status     = states.ERROR;
				               }),
				               builder.addCase(logOut.pending, (state) =>
					               {
						               state.ajaxStatus = states.PENDING;
					               },
				               ),
				               builder.addCase(logOut.fulfilled, (state, action) =>
					               {
						               destroyCookie(null, config.cookies.auth, {path: '/'});
						
						               state.ajaxStatus = states.SUCCESS;
						               state.user       = {
							               ...defaultAuthUser,
						               };
						               state.status     = states.ERROR;
					               },
				               ),
				               builder.addCase(logOut.rejected, (state, action) =>
					               {
						               state.ajaxStatus = states.ERROR;
						               state.status     = states.ERROR;
					               },
				               )
		               },
	},
);

export const {updateDarkMode, logIn} = authSlice.actions;

export default authSlice.reducer;


