import { ConfigType as AwsConfigType } from '@8ms/helpers/dist/aws';
import { platforms, PlatformsType } from '@8ms/helpers/dist/environment';
import { UrlType } from '@8ms/helpers/dist/url';

export type ConfigType = {
	athena: {
		athenaExpressS3: string,
		database: string,
	},
	googleAnalytics: string,
	platform: PlatformsType,
	aws: AwsConfigType,
	charts: {
		font: string,
	},
	cookies: {
		[cookieType: string]: string,
	},
	dashboard: {
		home: string,
		id: string,
		name: string,
	},
	lambda: {
		stackName: string,
	},
	ses: {
		from: string,
	},
	s3: {
		public: string,
		standard: string,
		versioned: string,
	},
	url: UrlType,
};

export const config: ConfigType = {
	athena:          {
		athenaExpressS3: 's3://bbcws-dashboard/athena-express',
		database:        'bbcws-dashboard',
	},
	aws:             {
		accessKeyId:     process.env.AWS_IAM_ACCESS_KEY_ID || '',
		region:          'eu-west-1',
		secretAccessKey: process.env.AWS_IAM_SECRET_KEY || '',
	},
	charts:          {
		font: 'Montserrat',
	},
	cookies:         {
		auth:        'authCookie',
		maintenance: 'maintenance',
	},
	dashboard:       {
		home: '/marketing/ytdRevised',
		id:   'BBCWSDashboard',
		name: 'BBCWS Dashboard',
	},
	googleAnalytics: 'UA-39894094-9',
	lambda: {
		stackName: 'bbcws-dashboard-aws',
	},
	platform:        platforms.AWS,
	s3:              {
		public:    '',
		standard:  'bbcws-dashboard',
		versioned: 'bbcws-dashboard-storage',
	},
	ses:             {
		from: `BBCWS Dashboard <development@8ms.com>`,
	},
	url:             {
		localhost:   'http://bbcws-dashboard.local:8080',
		development: 'http://bbcws-dashboard.local:8080',
		staging:     'https://staging.bbcws-dashboard.8ms.com',
		production:  'https://bbcws-dashboard.8ms.com',
	},
};
