import { getColor } from '@helpers/theme';

export const NAVIGATION_SIDE_THEME = {
	baseStyle: ({colorMode}) => ({
		body:            {
			bg:        getColor(colorMode, ['background', '80'], ['background', '5']),
			boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',
			flex:      1,
			overflow:  "auto",
		},
		button:          {
			display: 'none',
		},
		dialog:          {
			height:    "100vh",
			maxHeight: "100vh",
			maxWidth:  "16rem",
			zIndex:    "modal",
		},
		dialogContainer: {
			display:        "flex",
			justifyContent: "center",
			zIndex:         "modal",
		},
		footer:          {
			bg:        getColor(colorMode, ['background', 'base'], 'white'),
			boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',
			px:        '1.5rem',
			py:        '1rem',
		},
		header:          {
			bg:        getColor(colorMode, ['background', 'base'], 'white'),
			boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',
			px:        '1.5rem',
			py:        '1rem',
		},
		overlay:         {
			zIndex: "overlay",
		},
	}),
};
