import { TimeframeObjectType } from '@core/app/dates';
import { defaultLanguageService } from '@core/app/languageServices';
import { platformDefault } from '@core/app/platforms';
import { XtorType } from '@core/app/xtor';
import { ALL } from '@core/helpers/enum';
import { states, StatesType } from '@8ms/helpers/dist/api';
import { getLastWeek, getTwoWeeksAgo } from '@8ms/helpers/dist/date';
import { defaultTo } from '@8ms/helpers/dist/util';
import { LanguageService, Platform } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit';
import isUndefined from 'lodash/isUndefined';
import takeRight from 'lodash/takeRight';

export interface DashboardState
{
	ajaxStatus: StatesType,
	comparison: TimeframeObjectType,
	current: TimeframeObjectType,
	error: any,
	initalised: typeof states.ERROR | typeof states.SUCCESS,
	languageService: LanguageService,
	platform: Platform,
	router: {
		paths: string[],
	},
	xtor: XtorType,
}

const initialState: DashboardState = {
	ajaxStatus:      states.IDLE,
	comparison:      {
		...getTwoWeeksAgo(),
		enabled: false,
	},
	current:         {
		...getLastWeek(),
		enabled: true,
	},
	initalised:      states.ERROR,
	error:           null,
	languageService: defaultLanguageService,
	platform:        platformDefault,
	router:          {
		paths: [],
	},
	xtor:            {
		activity:        ALL,
		adSet:           ALL,
		contentCategory: ALL,
		contentSource:   ALL,
		contentType:     ALL,
		format:          ALL,
		gender:          ALL,
		newsNeed:        ALL,
	},
};

const dashboard = createSlice(
	{
		name:     'dashboard',
		initialState,
		reducers: {
			addRouterPath(state, action)
			{
				state.router.paths = takeRight([...state.router.paths, action.payload], 5);
			},
			init(state, action)
			{
				// Preferences are defined
				if (!isUndefined(action.payload.query['pref']))
				{
					const decoded = decodeURIComponent(action.payload.query.pref);
					const pref    = JSON.parse(decoded);
					
					// Current
					if (!isUndefined(pref['current']))
					{
						state.current.start = defaultTo({defaultValue: state.current.start, instance: pref, keys: ['current', 'start']});
						state.current.end   = defaultTo({defaultValue: state.current.end, instance: pref, keys: ['current', 'end']});
					}
					
					// Comparison
					if (!isUndefined(pref['comparison']))
					{
						state.comparison.start   = defaultTo({defaultValue: state.comparison.start, instance: pref, keys: ['comparison', 'start']});
						state.comparison.end     = defaultTo({defaultValue: state.comparison.end, instance: pref, keys: ['comparison', 'end']});
						state.comparison.enabled = defaultTo({defaultValue: state.comparison.enabled, instance: pref, keys: ['comparison', 'enabled']});
					}
					
					state.languageService = defaultTo({defaultValue: state.languageService, instance: pref, keys: ['languageService']});
					state.platform        = defaultTo({defaultValue: state.platform, instance: pref, keys: ['platform']});
					state.xtor            = defaultTo({defaultValue: state.xtor, instance: pref, keys: ['xtor']});
				}
				
				// Now initialised
				state.initalised = states.SUCCESS;
			},
			updateLanguageService(state, action)
			{
				state.languageService = action.payload;
			},
			updatePlatform(state, action)
			{
				state.platform = action.payload;
			},
			updateTimeframe(state, action)
			{
				state.current.start      = action.payload.current.start;
				state.current.end        = action.payload.current.end;
				state.comparison.start   = action.payload.comparison.start;
				state.comparison.end     = action.payload.comparison.end;
				state.comparison.enabled = action.payload.comparison.enabled;
			},
			updateXtor(state, action)
			{
				state.xtor.contentType     = action.payload.contentType;
				state.xtor.activity        = action.payload.activity;
				state.xtor.adSet           = action.payload.adSet;
				state.xtor.contentCategory = action.payload.contentCategory;
				state.xtor.contentSource   = action.payload.contentSource;
				state.xtor.format          = action.payload.format;
				state.xtor.gender          = action.payload.gender;
				state.xtor.newsNeed        = action.payload.newsNeed;
			},
		},
	},
);

export const {
	             addRouterPath,
	             init,
	             updateLanguageService,
	             updatePlatform,
	             updateTimeframe,
	             updateXtor,
             } = dashboard.actions;

export default dashboard.reducer;


