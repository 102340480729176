import { getColor } from '@helpers/theme';
import { states } from '@8ms/helpers/dist/api';

const THEME = {
	baseStyle: {
		description: {
			lineHeight: 'revert',
		},
	},
	variants:  {
		[states.ERROR]: ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['alert', 'base']),
				color: getColor(colorMode, 'white'),
			},
		}),
		/**
		[INFORMATION]: ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['additional', '0']),
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
		}),
		 */
		[states.SUCCESS]: ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['correct', 'base']),
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
		}),
		/**
		[WARNING]:     ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['warning', 'base']),
				color: getColor(colorMode, ['background', 'base']),
			},
		}),
		 */
	},
};

export default THEME;
