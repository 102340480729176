import { getTextColor } from '@components/theme';
import { getColor } from '@helpers/theme';

const buildPrimary = (colorMode) => ({
	bg:           getColor(colorMode, 'white'),
	borderColor:  getTextColor(colorMode),
	borderRadius: '0',
	borderWidth:  'light' === colorMode ? '1px' : '0',
	boxShadow:    '0 2px 4px 0 rgba(76,82,78,0.2)',
	color:        getColor(colorMode, ['background', 'base']),
	height:       '3rem',
	minWidth:     'auto',
	overflow:     'visible',
	position:     'relative',
	px:           4,
	textAlign:    'inherit',
	textStyle:    'primaryCta',
	_after:       {
		bg:         getColor(colorMode, ['background', '40']),
		content:    '""',
		height:     '0',
		left:       '50%',
		opacity:    '1',
		position:   'absolute',
		top:        '50%',
		transform:  'translate(-50%, -50%)',
		transition: '0.5s',
		width:      '0',
	},
	_focus:       {
		boxShadow: "none",
		outline:   "revert",
	},
	_hover:       {
		bg:     getColor(colorMode, ['background', '40']),
		color:  getColor(colorMode, ['background', 'base']),
		_after: {
			height:  '100%',
			opacity: '0',
			width:   '100%',
		},
	},
});

export const THEME = {
	baseStyle: {
		minW: 10,
	},
	variants:  {
		primary:         ({colorMode}) => buildPrimary(colorMode),
		primaryDropdown: ({colorMode}) => ({
			...buildPrimary(colorMode),
			minWidth:  '150px',
			textAlign: 'left',
		}),
		secondary:       ({colorMode}) => ({
			bg:           getColor(colorMode, ['background', 'base'], 'white'),
			borderRadius: 0,
			border:       '2px solid',
			borderColor:  getColor(colorMode, 'white', ['background', 'base']),
			color:        getColor(colorMode, 'white', ['background', 'base']),
			height:       '3rem',
			minWidth:     '80px',
			textStyle:    'secondaryCta',
			_after:       {
				bg:         getColor(colorMode, 'white', ['background', 'base']),
				content:    '""',
				top:        '50%',
				left:       "50%",
				width:      '0',
				height:     '0',
				position:   'absolute',
				transform:  'translate(-50%, -50%)',
				transition: '0.5s',
				opacity:    '1',
			},
			_focus:       {
				boxShadow: "none",
				outline:   "revert",
			},
			_hover:       {
				bg:     getColor(colorMode, ['background', 'base'], 'white'),
				_after: {
					height:  'calc(100% - 4px)',
					opacity: '1',
					width:   'calc(100% - 4px)',
				},
			},
		}),
		navigation:      ({colorMode}) => ({
			bg:             'transparent',
			borderRadius:   0,
			color:          getColor(colorMode, 'white', ['background', 'base']),
			padding:        '0.25rem',
			textDecoration: 'underline',
			textStyle:      'navigationPrimary',
			_focus:         {
				boxShadow: "none",
				outline:   "revert",
			},
			_hover:         {
				bg:    getColor(colorMode, 'white', ['background', 'base']),
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
		}),
		datatableExpand: ({colorMode}) =>
		                 {
			                 const primary = buildPrimary(colorMode);
			
			                 return {
				                 ...primary,
				                 borderRadius: '50%',
				                 height:       'auto',
				                 padding:      '0.125rem',
				                 _after:       {
					                 ...primary._after,
					                 borderRadius: '50%',
				                 },
			                 };
		                 },
	},
};

export default THEME;
