import { SingleOptionType } from '@core/app/dropdown';
import { buildOption } from '@core/helpers/dropdown';
import { Platform } from '@prisma/client';

/**
 * Platforms that are supported retrieving the data using Supermetrics.
 */
const platformsEsrRetrieve = [
	Platform.FACEBOOK,
	Platform.INSTAGRAM,
	Platform.YOUTUBE,
];

/**
 * Dropdown options used for Admin / Data Collection / Retrieve (ESR)
 */
export const retrieveEsrOptions: SingleOptionType[] = platformsEsrRetrieve.map(entry => buildOption(entry));

