import { getColor } from '@helpers/theme';

const THEME = {
	baseStyle: ({colorMode}) => ({
		body:            {
			px:       '0.5rem',
			py:       '0.5rem',
			flex:     1,
			overflow: "auto",
		},
		closeButton:     {},
		dialog:          {
			bg:      getColor(colorMode, ['background', 'base'], 'white'),
			color:   getColor(colorMode, 'white', ['background', 'base']),
			my:      "3.75rem",
			maxH:    "calc(100vh - 2rem)",
			padding: '1rem',
			width:   'auto',
			zIndex:  "modal",
		},
		dialogContainer: {
			alignItems:     "center",
			display:        "flex",
			justifyContent: "center",
			zIndex:         "modal",
		},
		footer:          {
			px: '0.5rem',
			py: '0.5rem',
		},
		header:          {},
		overlay:         {
			bg:     "overlay.background",
			zIndex: "modal",
		},
	}),
	variant:   {},
};


export default THEME;
