import { Progress, VStack } from '@chakra-ui/react'
import { createContext, ReactElement, useContext, useEffect, useRef, useState } from 'react'

type LoadingPageProviderType = {
	children: ReactElement | ReactElement[]
}

type ProgressType = {
	value: number
	start: () => void
	done: () => void
}

// 1. Creating a context
const LoadingPageContext = createContext<ProgressType>({
	value: 0,
	start: () => {},
	done:  () => {},
})

// 2. useLoadingPage hook
export const useLoadingPage = (): ProgressType =>
{
	return useContext<ProgressType>(LoadingPageContext)
}

// 3. LoadingPage component
const LoadingPage = () =>
{
	const {value} = useLoadingPage();
	
	return (
	<VStack align='flex-end'
	        position='fixed'
	        top={0}
	        left={0}
	        right={0}
	>
		<Progress value={value}
		          width='100%'
		          variant='loadingPage'
		/>
	</VStack>
	)
}

// 4. LoadingPageProvider

/**
 * https://dev.to/vladimirvovk/page-loading-progress-with-next-js-and-chakra-ui-h11
 * https://github.com/vladimir-vovk/nextjs-starter/tree/loading-progress
 */
export const LoadingPageProvider = ({children}: LoadingPageProviderType): ReactElement =>
{
	// 5. Variables
	const step              = useRef(5)
	const [value, setValue] = useState(0)
	const [isOn, setOn]     = useState(false)
	
	// 6. useEffect
	useEffect(() =>
	{
		if (isOn)
		{
			let timeout: NodeJS.Timeout;
			
			if (value < 20)
			{
				step.current = 5
			}
			else if (value < 40)
			{
				step.current = 4
			}
			else if (value < 60)
			{
				step.current = 3
			}
			else if (value < 80)
			{
				step.current = 2
			}
			else
			{
				step.current = 1
			}
			
			if (value <= 98)
			{
				timeout = setTimeout(() =>
				{
					setValue(value + step.current)
				}, 500)
			}
			
			return () =>
			{
				if (timeout)
				{
					clearTimeout(timeout)
				}
			}
		}
	}, [value, isOn])
	
	// 7. start
	const start = () =>
	{
		setValue(0);
		setOn(true);
	}
	
	// 8. done
	const done = () =>
	{
		setValue(100);
		setTimeout(() =>
		{
			setOn(false);
		}, 200)
	}
	
	return (
		<LoadingPageContext.Provider value={{
			value,
			start,
			done,
		}}>
			{isOn ? <LoadingPage/> : <></>}
			{children}
        </LoadingPageContext.Provider>
	)
}

