import { getTextColor } from '@components/theme';
import { getColor } from '@helpers/theme';

const THEME = {
	baseStyle: ({colorMode}) => ({
		container: {},
		track:     {
			bg:           getColor(colorMode, ['background', '20']), //'red', //    mode("gray.300", "whiteAlpha.400")(props),
			borderRadius: "full",
			width:        '1.5rem', //"var(--slider-track-width)",
			height:       '0.75rem', //"var(--slider-track-height)",
			padding:      "2px",
			transition:   "translateX 120ms",
			_focus:       {
				boxShadow: "outline",
			},
			_checked:     {
				bg: getColor(colorMode, ['background', '60']), //'blue', //mode(`${c}.500`, `${c}.200`)(props),
			},
		},
		thumb:     {
			bg:           getTextColor(colorMode), //)"orange",
			transition:   "transform 250ms",
			borderRadius: "inherit",
			width:        '0.75rem', //  "var(--slider-track-height)",
			height:       '0.75rem', //  "var(--slider-track-height)",
			_checked:     {
				transform: "translateX(calc(1.5rem - 0.75rem))", // - 25px))", //var(--slider-thumb-x))",
			},
		},
	}),
};

export default THEME;
