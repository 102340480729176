import { getNumber } from '@8ms/helpers/dist/date';
import { URL, URL_DECODE, URL_ENCODE } from '@core/helpers/enum';
import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

/**
 * Convert into JSON.
 */
export const getJson = (input: any): string =>
{
	let json;
	
	// Already a string
	if (isString(input))
	{
		json = input;
	}
	
	// Convert a number into a date
	else if (isNumber(input))
	{
		json = input;
	}
	
	// Object or array to JSON
	else if (isObject(input) || isArray(input))
	{
		json = JSON.stringify(input);
	}
	
	// Date
	else if (isDate(input))
	{
		json = getNumber({input})
			.toString();
	}
	
	return json;
}

/**
 * Convert into string.
 */
export const getString = (input: any): string =>
{
	let string = '';
	
	// Already a string
	if (isString(input))
	{
		string = input;
	}
	
	// Convert a number into a date
	else if (isNumber(input))
	{
		string = input.toString();
	}
	
	// Object or array to JSON
	else if (isObject(input) || isArray(input))
	{
		string = JSON.stringify(input);
	}
	
	// Date
	else if (isDate(input))
	{
		string = getNumber({input})
			.toString();
	}
	
	return string;
}

/**
 * Return a URL.
 */
export const formatString = (input: any, format: string): string =>
{
	let stringInstance = getString(input);
	
	switch (format)
	{
		case URL:
			return stringInstance;
		
		case URL_DECODE:
			return decodeURI(stringInstance);
		
		case URL_ENCODE:
			return encodeURI(stringInstance);
		
		default:
			return stringInstance;
	}
}

/**
 * Return a string "With Proper Casing".
 */
export const toProperCase = (input: any): string =>
{
	const preparedInput = getString(input);
	
	let response = input;
	
	let arraySplit = preparedInput.split('_');
	
	// There are more than one words split by underscore
	if (arraySplit.length > 1)
	{
		response = arraySplit.join(' ');
	}
	
	// Camel Casing to proper words
	else
	{
		response = input.replace(/([a-z](?=[A-Z]))/g, '$1 ');
	}
	
	// Convert words into Proper Casing
	response = response.replace(/\w\S*/g, word => word.charAt(0)
		.toUpperCase() + word.substr(1)
		.toLowerCase());
	
	return response;
}
