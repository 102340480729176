import { getBackgroundColor, getTextColor } from '@components/theme';

const THEME = {
	baseStyle: ({colorMode}) => ({
		tab:      {
			//	borderRadius: "full",
			//	fontWeight:   "semibold",
			// color:        'blue', // mode("gray.600", "inherit")(props),
			padding:   '0.5rem 1rem',
			_selected: {
				background: getTextColor(colorMode), //)'red', //mode(`${c}.600`, `${c}.300`)(props),
				color:      getBackgroundColor(colorMode), //'white', //mode(`#fff`, "gray.800")(props),
				//	bg:    'red', //mode(`${c}.600`, `${c}.300`)(props),
			},
		},
		tablist:  {
			flex:         1, // isFitted ? 1 : undefined,
			marginBottom: "-1px",
			borderBottom: "1px solid",
			borderColor:  "inherit",
			transition:   "all 0.2s",
			_focus:       {
				zIndex:    1,
				boxShadow: "outline",
			},
		},
		tabpanel: {
			padding: '0.5rem 0 0',
		},
	}),
};

export default THEME;
