import { getTextColor } from '@components/theme';
import { getColor } from '@helpers/theme';

export const COPY_THEME = {
	baseStyle: ({colorMode}) => ({
		color: getTextColor(colorMode),
	}),
	variants:  {
		'primaryCta':   ({colorMode}) => ({
			color: getColor(colorMode, ['background', 'base']),
		}),
		'secondaryCta': ({colorMode}) => ({
			color: getTextColor(colorMode),
		}),
	},
};

export const HEADING_THEME = {
	baseStyle: {},
}
