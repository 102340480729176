import { useMediaQuery } from "@chakra-ui/react"

export const BREAKPOINT_LABELS = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

// Breakpoints used in the site
export const BREAKPOINTS = {
	xs:  {
		min: 0,// parseInt(style['xs-min'], 10),
		max: 575, // parseInt(style['xs-max'], 10),
	},
	sm:  {
		min: 576, //parseInt(style['sm-min'], 10),
		max: 767, //parseInt(style['sm-max'], 10),
	},
	md:  {
		min: 768, //parseInt(style['md-min'], 10),
		max: 991, //parseInt(style['md-max'], 10),
	},
	lg:  {
		min: 992, // parseInt(style['lg-min'], 10),
		max: 1199, //parseInt(style['lg-max'], 10),
	},
	xl:  {
		min: 1200, //parseInt(style['xl-min'], 10),
		max: 1399, //parseInt(style['xl-max'], 10),
	},
	xxl: {
		min: 1400, //parseInt(style['xxl-min'], 10),
		max: 9999,//parseInt(style['xxl-max'], 10),
	},
}

export type BreakpointInputType = {
	xs?: any,
	sm?: any,
	md?: any,
	lg?: any,
	xl?: any,
	xxl?: any,
}

/**
 * Define the max-widths for the breakpoint, must use "2xl" for "xxl" due to Chakra UI
 */
export const CHAKRA_BREAKPOINTS = {
	base:  `${BREAKPOINTS.xs.min}`,
	sm:    `${BREAKPOINTS.sm.min}px`,
	md:    `${BREAKPOINTS.md.min}px`,
	lg:    `${BREAKPOINTS.lg.min}px`,
	xl:    `${BREAKPOINTS.xl.min}px`,
	"2xl": `${BREAKPOINTS.xxl.min}px`,
};

export const useIsXS = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.xs.min}px) and (max-width:  ${BREAKPOINTS.xs.max}px)`);
	return valid;
}

export const useIsXSOrGreater = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.xs.min}px)`);
	return valid;
}

export const useIsSM = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.sm.min}px) and (max-width:  ${BREAKPOINTS.sm.max}px)`);
	return valid;
}

export const useIsSMOrGreater = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.sm.min}px)`);
	return valid;
}

export const useIsMD = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.md.min}px) and (max-width:  ${BREAKPOINTS.md.max}px)`);
	return valid;
}

export const useIsMDOrGreater = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.md.min}px)`);
	return valid;
}

export const useIsLG = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.lg.min}px) and (max-width:  ${BREAKPOINTS.lg.max}px)`);
	return valid;
}

export const useIsLGOrGreater = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.lg.min}px)`);
	return valid;
}

export const useIsXL = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.xl.min}px) and (max-width:  ${BREAKPOINTS.xl.max}px)`);
	return valid;
}

export const useIsXLOrGreater = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.xl.min}px)`);
	return valid;
}

export const useIsXXL = (): boolean =>
{
	const [valid] = useMediaQuery(`(min-width: ${BREAKPOINTS.xxl.min}px) and (max-width:  ${BREAKPOINTS.xxl.max}px)`);
	return valid;
}

/**
 * For the given input return an array for each of the breakpoints in order of xs to xxl
 */
export const getPopulatedBreakpoints = (input: BreakpointInputType): any[] =>
{
	let updated = {
		xs:  undefined,
		sm:  undefined,
		md:  undefined,
		lg:  undefined,
		xl:  undefined,
		xxl: undefined,
	};
	
	// Loop through each breakpoints to see if its defined
	BREAKPOINT_LABELS.map(label =>
	{
		if (undefined !== input[label])
		{
			updated[label] = input[label];
		}
	});
	
	// Mobile first, missed back fill to populate
	if (undefined === updated['xs'])
	{
		// Loop through each breakpoints to see if its defined
		for (let j = 0; j < BREAKPOINT_LABELS.length; j++)
		{
			if (undefined === updated[BREAKPOINT_LABELS[j]])
			{
				for (let k = 0; k < j; k++)
				{
					if (undefined === updated[BREAKPOINT_LABELS[k]])
					{
						updated[BREAKPOINT_LABELS[k]] = updated[BREAKPOINT_LABELS[j]]
					}
				}
				
				// Exit the loop so only XS useIs populated
				break;
			}
		}
	}
	
	// Missing future values - use previous value
	for (let m = 0; m < BREAKPOINT_LABELS.length; m++)
	{
		if (undefined === updated[BREAKPOINT_LABELS[m]])
		{
			updated[BREAKPOINT_LABELS[m]] = updated[BREAKPOINT_LABELS[m - 1]];
		}
	}
	
	// Return an array of populated entries
	return [updated.xs, updated.sm, updated.md, updated.lg, updated.xl, updated.xxl];
}
