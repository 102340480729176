import { getColor } from '@helpers/theme';

export const METRIC_THEME = {
	baseStyle: ({colorMode}) => ({
		filledTrack: {
			bg: getColor(colorMode, ['additional', '0'], ['additional', '1']),
		},
		track:       {
			bg:     getColor(colorMode, ['background', '60']),
			height: '10px',
			width:  '100%',
		},
	}),
};
