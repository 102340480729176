import { Platform } from '@prisma/client';

/**
 * Platforms supported.
 */
export const platforms = [
	Platform.CUSTOM,
	Platform.ESKIMI,
	Platform.FACEBOOK,
	Platform.GOOGLE_ADS_DISPLAY,
	Platform.GOOGLE_ADS_SEARCH,
	Platform.INSTAGRAM,
	Platform.MY_TARGET,
//	Platform.NYTIVE,
//	Platform.PSIPHON,
	Platform.QUORA,
	Platform.SNAPCHAT,
	Platform.SPOTIFY,
	Platform.TWITTER,
	Platform.YANDEX_DISPLAY,
	Platform.YANDEX_SEARCH,
	Platform.YOUTUBE,
];
