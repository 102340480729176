import { getTextColor } from '@components/theme';
import { getColor } from '@helpers/theme';

export const THEME = {
	baseStyle: ({colorMode}) => ({
		filledTrack: {
			bg: getColor(colorMode, ['additional', '0']),
		},
		track:       {
			bg:     getColor(colorMode, ['background', '60']),
			height: '4px',
			width:  '100%',
		},
	}),
	variants:  {
		loadingPage: ({colorMode}) => ({
			filledTrack: {
				bg: getTextColor(colorMode),
			},
		}),
		negative:    ({colorMode}) => ({
			filledTrack: {
				bg: getColor(colorMode, ['alert', 'base']),
			},
		}),
		positive:    ({colorMode}) => ({
			filledTrack: {
				bg: getColor(colorMode, ['correct', 'base']),
			},
		}),
	},
};

export default THEME;
