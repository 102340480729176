import { SingleOptionType } from '@core/app/dropdown';
import { ALL } from '@core/helpers/enum';
import { toProperCase } from '@core/helpers/string';

/**
 * Function to convert a selected option similar to a Dropdown option.
 * Only works with options created with this similar method (enums).
 */
export const buildOption = (input: string): SingleOptionType => ({
	label: toProperCase(input),
	key:   input,
	value: input,
})

/**
 * Create the instance of "All".
 */
export const ALL_OPTION: SingleOptionType = {
	label: 'All',
	key:   ALL,
	value: ALL,
}
