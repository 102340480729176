import { getPopulatedBreakpoints } from './breakpoints';

/**
 * regular = 500
 * medium = 500
 * semi bold = 600
 * bold = 700
 */
const TEXT_STYLES = {
	h1:                {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '2.125rem', sm: '2.25rem', md: '2.5rem', lg: '2.875rem'}),
		fontWeight:    600,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.5px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '40px', sm: '42px', md: '46px', lg: '50px'}),
	},
	h2:                {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1.625rem', sm: '1.75rem', md: '2rem', lg: '1.875rem'}),
		fontWeight:    600,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.4px', sm: '-0.5px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '34px', sm: '37px', md: '43px', lg: '46px'}),
	},
	h3:                {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1.375rem', sm: '1.5rem', md: '1.625rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.3px', sm: '-0.4px', md: '-0.5px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '29px', sm: '32px', md: '34px'}),
	},
	h4:                {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1.375rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.3px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '29px'}),
	},
	h5:                {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1.125rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.3px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '24px'}),
	},
	lead:              {
		fontFamily:    'Montserrat',
		fontSize:      getPopulatedBreakpoints({xs: '1.063rem', lg: '1.25rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '0'}),
		lineHeight:    getPopulatedBreakpoints({xs: '24px', lg: '30px'}),
	},
	body:              {
		fontFamily:    'Montserrat',
		fontSize:      getPopulatedBreakpoints({xs: '1rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.27px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '22px'}),
	},
	caption:           {
		fontFamily:    'Montserrat',
		fontSize:      getPopulatedBreakpoints({xs: '0.75rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '0'}),
		lineHeight:    getPopulatedBreakpoints({xs: '14px'}),
	},
	captionLabel:      {
		fontFamily:    'Montserrat',
		fontSize:      getPopulatedBreakpoints({xs: '0.75rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '0'}),
		lineHeight:    getPopulatedBreakpoints({xs: '14px'}),
		marginBottom:  '0.5rem',
	},
	primaryCta:    {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1rem'}),
		fontWeight:    700,
		letterSpacing: getPopulatedBreakpoints({xs: '0'}),
		lineHeight:    getPopulatedBreakpoints({xs: '22px'}),
	},
	secondaryCta:  {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1rem'}),
		fontWeight:    500,
		letterSpacing: getPopulatedBreakpoints({xs: '0'}),
		lineHeight:    getPopulatedBreakpoints({xs: '22px'}),
	},
	navigationPrimary: {
		fontFamily:    'Raleway',
		fontSize:      getPopulatedBreakpoints({xs: '1.125rem'}),
		fontWeight:    700,
		letterSpacing: getPopulatedBreakpoints({xs: '-0.3px'}),
		lineHeight:    getPopulatedBreakpoints({xs: '29px'}),
	},
}

export type TextStyleType = {
	textStyle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'lead' | 'body' | 'caption' | 'captionLabel' | 'primaryCta' | 'secondaryCta' | 'navigationPrimary',
}


export default TEXT_STYLES;
