import { SingleOptionType } from '@core/app/dropdown';
import { toProperCase } from '@core/helpers/string';
import { Platform } from '@prisma/client';

/**
 * Campaigns used when creating a Campaign
 */
const platformsCampaigns = [
	Platform.CUSTOM,
	Platform.ESKIMI,
	Platform.FACEBOOK,
	Platform.GOOGLE_ADS,
	Platform.MY_TARGET,
//	Platform.NYTIVE,
//	Platform.PSIPHON,
	Platform.QUORA,
	Platform.SNAPCHAT,
	Platform.SPOTIFY,
	Platform.TWITTER,
	Platform.YANDEX,
];

/**
 * Dropdown options used for Admin / Campaigns
 */
export const campaignOptions: SingleOptionType[] = platformsCampaigns
	.map(entry =>
	{
		const label = Platform.FACEBOOK === entry ? 'Facebook & Instagram' : entry;
		
		return {
			label: toProperCase(label),
			key:   entry,
			value: entry,
		}
	});
