import { Link } from '@components/Link';
import { useLoadingPage } from '@components/Progress/LoadingPage';
import { RootState } from '@components/redux/store';
import Image from 'next/image';
import Router from 'next/router';
import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';

type AppType = {
	children?: ReactNode
}

const _App = ({children}: AppType) =>
{
	// Load dark mode
	const darkMode = useSelector((state: RootState) => state.auth.darkMode);

	// Show loading page progress bar
	const {start, done} = useLoadingPage();

	const onRouteChangeStart = () => {start()}

	const onRouteChangeComplete = () =>
	{
		setTimeout(() => {done()}, 1)
	}

	useEffect(() =>
	{
		Router.events.on('routeChangeStart', onRouteChangeStart);
		Router.events.on('routeChangeComplete', onRouteChangeComplete);
		Router.events.on('routeChangeError', onRouteChangeComplete);

		return () =>
		{
			Router.events.off('routeChangeStart', onRouteChangeStart);
			Router.events.off('routeChangeComplete', onRouteChangeComplete);
			Router.events.off('routeChangeError', onRouteChangeComplete);
		}
	}, [])

	useEffect(() =>
	{
		const html = document.querySelector('html');

		if (darkMode)
		{
			html.classList.remove('light');
			html.classList.add('dark');
		}
		else
		{
			html.classList.remove('dark');
			html.classList.add('light');
		}
	}, [darkMode]);


	const emsLogo = darkMode ? '/logos/8ms_white.svg' : '/logos/8ms_grey.svg';

	return (
		<>
			{children}
			<div className='bg-background-light-5 dark:bg-background-dark-80 mt-24 pb-8 pt-4 shadow-box text-center'>
				<div className='container'>
					<div className='row'>
						<div className='column w-full'>
							<div className='flex justify-center'>
								<Link href='/documentation'>Documentation</Link>
								<Link href='/terms'>Terms & Conditions</Link>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='column w-full'>
							<div className='flex justify-center mt-3'>
								<div className='mr-2'>
									<Image
										src='/logos/bbc.svg'
										height={92}
										width={92}
									/>
								</div>
								<div>
									<Image
										src={emsLogo}
										height={92}
										width={92}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='py-4 shadow-box text-center'>
				<div className='container'>
					<div className='row'>
						<div className='column w-full'>
							<p className='caption'>All rights reserved {new Date().getFullYear()} &copy; 8 Million Stories</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export const getLayout = page => <_App>{page}</_App>;

export default _App;
