// Data Type
export const DIMENSION: string = 'dimension';
export const METRIC: string    = 'metric';

// Formats - Boolean
export const BOOLEAN: string = 'boolean';

// Formats - Number
export const CURRENCY_EUR: string         = 'currency_eur';
export const CURRENCY_GBP: string         = 'currency_gbp';
export const CURRENCY_USD: string         = 'currency_usd';
export const NUMBER: string               = 'number';
export const NUMBER_2DP: string           = 'number_2dp';
export const NUMBER_2DP_THOUSANDS: string = 'number_2dp_thousands';
export const NUMBER_3DP_THOUSANDS: string = 'number_3dp_thousands';
export const NUMBER_SIMPLIFIED: string    = 'number_simplified';
export const NUMBER_THOUSANDS: string     = 'number_thousands';
export const PERCENT: string              = 'percent';
export const PERCENT_DIVIDE_100: string   = 'percent_divide_100';
export const PERCENT_NUMBER: string       = 'percent_number';

// Formats - String
export const STRING: string           = 'string';
export const HTML: string             = 'html';
export const JSON_FORMAT: string      = 'json';
export const SQL: string              = 'sql';
export const URL: string              = 'url';
export const URL_DECODE: string       = 'url_decode';
export const URL_ENCODE: string       = 'url_encode';

// Statuses - Active/Inactive
export const ACTIVE: string   = 'active';
export const INACTIVE: string = 'inactive';

// Used in dropdowns
export const ALL: string = 'all';

// Automation
export const AUTOMATIC: string = 'automatic';
export const MANUAL: string    = 'manual';
export type AutomationType = typeof AUTOMATIC | typeof MANUAL;
