import { SingleOptionType } from '@core/app/dropdown';
import { toProperCase } from '@core/helpers/string';
import { Platform } from '@prisma/client';

/**
 * Campaigns used when in the Platform report.
 */
export const platformsReports = [
	Platform.ESKIMI,
	Platform.FACEBOOK,
	Platform.GOOGLE_ADS_DISPLAY,
	Platform.GOOGLE_ADS_SEARCH,
	Platform.SNAPCHAT,
	Platform.YOUTUBE,
];


/**
 * Platform Report, select options and Facebook data includes Instagram due to breakdown limitations in the API.
 */
export const reportOptions: SingleOptionType[] = platformsReports
	.map(entry =>
	{
		const key = Platform.FACEBOOK === entry ? 'Facebook & Instagram' : entry;
		
		return {
			label: toProperCase(key),
			key:   entry,
			value: entry,
		}
	});
