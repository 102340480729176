import { TextStyleType } from '@components/textStyles';
import { Link as ChakraLink } from '@chakra-ui/react';
import NextLink from 'next/link';
import { ReactNode } from 'react';

export type LinkType = TextStyleType & {
	children?: ReactNode,
	href: string | object,
	isExternal?: boolean,
	overrides?: {
		chakra?: {},
		link?: {},
	},
	variant?: string,
}

const Link = ({children, href, isExternal, overrides, textStyle, variant}: LinkType) =>
{
	return (
		<NextLink href={href}
		          passHref
		          {...overrides.link}
		>
			<ChakraLink isExternal={isExternal}
			            textStyle={textStyle}
			            variant={variant}
			            {...overrides.chakra}
			>
				{children}
			</ChakraLink>
		</NextLink>
	);
}

Link.defaultProps = {
	children:   undefined,
	href:       '',
	isExternal: false,
	overrides:  {},
	textStyle:  'body',
	variant:    '',
};

export default Link;
