import { config } from '@core/app/config';
import Theme from '@components/theme';
import { LoadingPageProvider } from '@components/Progress/LoadingPage';
import LayoutApp from '@layouts/_App';
import store from '@components/redux/store';
import '@tailwind/tailwind.scss';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import type { AppLayoutProps } from 'next/app'
import Head from 'next/head';
import { Provider as ReactReduxProvider } from 'react-redux'
import { SWRConfig } from 'swr';

const MyApp = ({Component, pageProps}: AppLayoutProps) =>
{
	const getLayout = Component.getLayout || (page => <LayoutApp>{page}</LayoutApp>);
	
	return (
		<SWRConfig value={{revalidateOnFocus: false, shouldRetryOnError: false}}>
			<ReactReduxProvider store={store}>
				<ChakraProvider theme={Theme}>
					<LoadingPageProvider>
						<Head>
							<title>{config.dashboard.name}</title>
							<meta
								name='robots'
								content='noindex, nofollow'
							/>
							<meta
								name='viewport'
								content='minimum-scale=1, initial-scale=1, width=device-width'
							/>
						</Head>
						{/** @ts-ignore //todo: Need to resolve typescript error */}
						<ColorModeScript initialColorMode={Theme.config.initialColorMode}/>
						{getLayout(<Component {...pageProps}/>)}
					</LoadingPageProvider>
				</ChakraProvider>
			</ReactReduxProvider>
		</SWRConfig>
	);
}

export default MyApp;
