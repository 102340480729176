import { isDevelopment } from '@8ms/helpers/dist/environment';
import authSlice, { AuthState } from '@components/redux/slices/auth';
import dashboardSlice, { DashboardState } from '@components/redux/slices/dashboard';
import { configureStore } from '@reduxjs/toolkit';

export interface RootState
{
	auth: AuthState,
	dashboard: DashboardState
}

export default configureStore({
	reducer:  {
		auth:      authSlice,
		dashboard: dashboardSlice,
	},
	devTools: isDevelopment(),
});
