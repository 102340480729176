// import theme from '@core/app/scss/theme.module.scss';
import TEXT_STYLES from '@components/textStyles';
import ALERT_THEME from '@components/Alert/theme';
import BOX_THEME from '@components/Box/theme';
// import BREADCRUMB_THEME from '@components/Breadcrumbs/theme';
import BUTTON_THEME from '@components/Button/theme';
import { METRIC_THEME } from '@components/Chart/theme';
import { DROPDOWN_MULTIPLE_THEME, DROPDOWN_SINGLE_THEME } from '@components/Dropdown/theme';
import { CHECKBOX_THEME, INPUT_THEME } from '@components/Form/theme';
//import HORIZONTAL_RULE_THEME from '@components/HorizontalRule/theme';
import LINK_THEME from '@components/Link/theme';
import MODAL_DASHBOARD_THEME from '@components/Modal/theme';
import { NAVIGATION_SIDE_THEME } from '@components/Navigation/theme';
import PROGRESS_THEME from '@components/Progress/theme';
import SWITCH_THEME from '@components/Switch/theme';
import TABS_THEME from '@components/Tabs/theme';
import TOOLTIP_THEME from '@components/Tooltip/theme';
import { COPY_THEME, HEADING_THEME } from '@components/Typography/theme';
import { CHAKRA_BREAKPOINTS } from '@components/breakpoints';
import { getColor, resetComponent } from '@helpers/theme';
import { theme as ChakraTheme } from '@chakra-ui/react';
import merge from 'lodash/merge';

/**
 * https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components
 */
const COMPONENTS = {
	Alert:      resetComponent('Alert', ALERT_THEME),
	Button:     resetComponent('Button', BUTTON_THEME),
	// Breadcrumb: resetComponent('Breadcrumb', BREADCRUMB_THEME),
	Checkbox:   resetComponent('Checkbox', CHECKBOX_THEME),
	CustomBox:  resetComponent('CustomBox', BOX_THEME),
	//Divider:    resetComponent('Divider', HORIZONTAL_RULE_THEME),
	Drawer:     resetComponent('Drawer', NAVIGATION_SIDE_THEME),
	Heading:    resetComponent('Heading', HEADING_THEME),
	Input:      resetComponent('Input', INPUT_THEME),
	Link:       resetComponent('Link', LINK_THEME),
	Menu:       resetComponent('Menu', {
		baseStyle: {
			list: {
				borderRadius: 0,
				zIndex:       10,
			},
		},
		variants:  {
			...DROPDOWN_MULTIPLE_THEME,
			...DROPDOWN_SINGLE_THEME,
		},
	}),
	Modal:      resetComponent('Modal', MODAL_DASHBOARD_THEME),
	Progress:   resetComponent('Progress', PROGRESS_THEME),
	Stat:       resetComponent('Stat', METRIC_THEME),
	Switch:     resetComponent('Switch', SWITCH_THEME),
	Tabs:       resetComponent('Tabs', TABS_THEME),
	Text:       resetComponent('Text', COPY_THEME),
	Tooltip:    resetComponent('Tooltip', TOOLTIP_THEME),
}

export const CHART_COLOURS = {
	light: [
		'#2e9550', //theme['correct-light-base'],
		'#d94832', ///theme['alert-light-base'],
		'#db732d', //theme['warning-light-base'],
		'#202e80', //theme['additional-light-v0'],
		'#7866ff', //	theme['additional-light-v1'],
		'#ff85b2', //	theme['additional-light-v2'],
		'#ff85b2', //	theme['additional-light-v3'],
		'#0d2745', //theme['additional-light-v4'],
		'#383532',// theme['background-light-v0'],
		'#383532',// theme['background-light-v0'],
		'#383532',// theme['background-light-v0'],
		'#383532',// theme['background-light-v0'],
		'#383532',// theme['background-light-v0'],
		'#383532',// theme['background-light-v0'],
	],
	dark:  [
		'#61f291', //theme['correct-dark-base'],
		'#ff4d4d', //theme['alert-dark-base'],
		'#ffc107', //theme['warning-dark-base'],
		'#ff93ff', //theme['additional-dark-v0'],
		'#2fcfd6', //theme['additional-dark-v1'],
		'#ff85b2', //theme['additional-dark-v2'],
		'#f8ab88', //theme['additional-dark-v3'],
		'#a7a8ef', //theme['additional-dark-v4'],
		'#ffffff', //	theme['background-dark-v0'],
		'#ffffff', //theme['background-dark-v0'],
		'#ffffff', //theme['background-dark-v0'],
		'#ffffff', //theme['background-dark-v0'],
		'#ffffff', //theme['background-dark-v0'],
		'#ffffff', //theme['background-dark-v0'],
	],
};

export const COLORS = {
	alert:      {
		dark:  {
			base:   '#ff4d4d', //theme['alert-dark-base'],
			80:    '#ff8371', //theme['alert-dark-v80'],
		},
		light: {
			base:  '#d94832', //theme['alert-light-base'],
			80:  '#ec6d5b', //theme['alert-light-v80'],
		},
	},
	correct:    {
		dark:  {
			base:    '#61f291', //theme['correct-dark-base'],
			80:   '#81f5a7', //theme['correct-dark-v80'],
		},
		light: {
			base:  '#2e9550', //theme['correct-light-base'],
			80:  '#1b7d3c', // theme['correct-light-v80'],
		},
	},
	background: {
		dark:  {
			base: '#383532', //theme['background-dark-base'],
			80:    '#605d5b', //theme['background-dark-v80'],
			60:   '#888684', //theme['background-dark-v60'],
			40:   '#afaead', //theme['background-dark-v40'],
			20:   '#d7d7d6', //theme['background-dark-v20'],
			5:     '#f5f5f5', //theme['background-dark-v5'],
			0:   '#ffffff', //theme['background-dark-v0'],
		},
		light: {
			base:  '#383532', //theme['background-light-base'],
			80:   '#605d5b', //theme['background-light-v80'],
			60:  '#888684', // theme['background-light-v60'],
			40:   '#afaead', //theme['background-light-v40'],
			20:   '#d7d7d6', //theme['background-light-v20'],
			5:    '#f5f5f5', //theme['background-light-v5'],
			0:    '#ffffff', //theme['background-light-v0'],
		},
	},
	warning:    {
		dark:  {
			base:  '#ffc107', //theme['warning-dark-base'],
			80:  '#ffcd39', // theme['warning-dark-v80'],
		},
		light: {
			base: '#db732d', //theme['warning-light-base'],
			80:   '#be5d1d', //theme['warning-light-v80'],
		},
	},
	additional: {
		dark:  {
			0: '#ff93ff', //theme['additional-dark-v0'],
			1: '#2fcfd6', //theme['additional-dark-v1'],
			2: '#ff85b2', //theme['additional-dark-v2'],
			3: '#f8ab88', //theme['additional-dark-v3'],
			4: '#a7a8ef', //theme['additional-dark-v4'],
		},
		light: {
			0: '#202e80', //theme['additional-light-v0'],
			1: '#7866ff', //theme['additional-light-v1'],
			2: '#ff85b2', //theme['additional-light-v2'],
			3: '#640099', //theme['additional-light-v3'],
			4: '#0d2745', //theme['additional-light-v4'],
		},
	},
	overlay:    {
		background: 'rgba(0, 0, 0, 0.8)', //theme['overlay-background'],
	},
	white:  '#ffffff', //    theme['white'],
};

// Shorthand functions to return a color based on the color mode
export const getBackgroundColor = colorMode => getColor(colorMode, ['background', 'base'], 'white');
export const getTextColor       = colorMode => getColor(colorMode, 'white', ['background', 'base']);

// Maximum widths for the container at given breakpoints
// export const CONTAINER_MAX_WIDTHS = ['100%', '540px', '720px', '960px', '1140px', '1400px'];

const CUSTOM_THEME = {
	...ChakraTheme,
	breakpoints:        CHAKRA_BREAKPOINTS,
	colors:             COLORS,
	components:         merge(ChakraTheme.components, COMPONENTS),
	config:             {
		initialColorMode: "dark",
	},
	fonts:              {
		heading: "Raleway",
		body:    "Montserrat",
	},
	sizes:              {},
	styles:             {
		global: ({colorMode}) => ({
			body: {
				background: getBackgroundColor(colorMode),
				color:      getTextColor(colorMode),
			},
		}),
	},
	textStyles:         TEXT_STYLES,
	useSystemColorMode: false,
}

export default CUSTOM_THEME;
